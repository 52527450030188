import { useState, useEffect } from "preact/hooks";

const Monitor = () => {
  const [idToken, setIdToken] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showTokens, setShowTokens] = useState<boolean>(false); // Toggle token display

  // Function to fetch tokens from the API
  const fetchTokens = async () => {
    try {
      const response = await fetch("/api/oauth_check-tokens", {
        method: "GET",
        credentials: "include", // Send cookies with request
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.error);
        setTimeout(() => setError(null), 0); // Hide error after 5 seconds
        return;
      }

      const data = await response.json();
      setIdToken(data.idToken);
      setAccessToken(data.accessToken);
    } catch (_err) {
      setError("Failed to fetch tokens");
      setTimeout(() => setError(null), 5000); // Hide error after 5 seconds
    }
  };

  // Fetch tokens on component mount and every 5 minutes
  useEffect(() => {
    fetchTokens();
    const interval = setInterval(fetchTokens, 600000); // Fetch tokens every 5 minutes
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  // Button color depending on token availability
  const buttonColor = idToken && accessToken ? "bg-slate-400 hover:bg-slate-300 p-0.5" : "bg-slate-300 hover:bg-slate-200 p-0.5";

  return (
    <div class="p-0 mt-0 mb-2 md:p-2 text-xs md:text-sm max-w-full">
      <div class="flex justify-end items-center">
        <button
          class={`${buttonColor} text-white py-0.5 px-1 rounded md:px-2`}
          onClick={() => setShowTokens(!showTokens)}
        >
          {showTokens ? "Hide Tokens (for Demo)" : "Show Tokens (for Demo)"}
        </button>
      </div>
      {error && <p class="text-orange-400 text-[0.6rem] md:text-xs text-right">{error}</p>}
      {showTokens && (
        <div class="mt-1 text-[0.5rem] md:text-xs">
          <div class="flex justify-end">
            <div class="text-right mr-1">
              <strong>ID Token:</strong>
            </div>
            <div class="text-right text-slate-400 break-all max-w-full">
              {idToken ? idToken : "none"}
            </div>
          </div>
          <div class="flex justify-end mt-1">
            <div class="text-right mr-1">
              <strong>Access Token:</strong>
            </div>
            <div class="text-right text-slate-600 break-all max-w-full">
              {accessToken ? accessToken : "none"}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Monitor;
